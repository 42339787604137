import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const workPlatformModule = namespace('workPlatform');
let homeLatelyApp = class homeLatelyApp extends Vue {
    setRecentApp(id) {
        const ids = this.recentIds;
        let idsArr = [];
        if (Array.isArray(ids)) {
            idsArr = ids.filter((item) => item !== id);
        }
        idsArr.unshift(id);
        this.setRecentIds(idsArr);
    }
};
__decorate([
    workPlatformModule.State('recentApps')
], homeLatelyApp.prototype, "recentApps", void 0);
__decorate([
    workPlatformModule.State('recentIds')
], homeLatelyApp.prototype, "recentIds", void 0);
__decorate([
    workPlatformModule.Action('setRecentIds')
], homeLatelyApp.prototype, "setRecentIds", void 0);
homeLatelyApp = __decorate([
    Component({
        name: 'homeLatelyApp',
        components: {
            AppItem: () => import('./app-item'),
        },
    })
], homeLatelyApp);
export default homeLatelyApp;
